import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      { path: '', loadChildren: () => import('./tour-planning/tour-planning.module').then(m => m.TourPlanningModule) },
      { path: 'tourPlanning', loadChildren: () => import('./tour-planning/tour-planning.module').then(m => m.TourPlanningModule) },
      { path: 'signIn', loadChildren: () => import('./sign-in-module/sign-in.module').then(m => m.SignInModule) },
      { path: 'baseData', loadChildren: () => import('./base-data/base-data.module').then(m => m.BaseDataModule) },
      { path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
      { path: '**', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  declarations: []
})
export class AppRoutingModule { }

