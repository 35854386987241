// import @ngrx
import { Action } from '@ngrx/store';

import { User } from './../shared/entities/user';
import { Setting } from '../shared/entities/setting';

// import models
export const AUTHENTICATE = '[users] Authenticate';
export const AUTHENTICATE_ERROR = '[users] Authentication error';
export const AUTHENTICATE_SUCCESS = '[users] Authentication success';
export const LOAD_AUTHENTICATED_USER = '[users] Load user';
export const LOAD_AUTHENTICATED_USER_ERROR = '[users] Load user error';
export const LOAD_AUTHENTICATED_USER_SUCCESS = '[users] Load user success';
export const SIGN_OUT = '[users] Sign off';
export const SIGN_OUT_ERROR = '[users] Sign off error';
export const SIGN_OUT_SUCCESS = '[users] Sign off success';
export const SIGN_OUT_SESSION_TIMEOUT = '[users] Sign off because of a session timeout';
export const TOKEN_REFRESH_ACTION_SUCCESS = '[users] Refressh access token success';
export const SWITCH_TENANT = '[session] Switch tenant';
export const SWITCH_TENANT_SUCCESS = '[session] Switch tenant success';
export const SWITCH_TENANT_ERROR = '[session] Switch tenant error';
export const SWITCH_TENANT_REFRESH_TOKEN = '[session] Switch tenant refresh token';
export const SWITCH_TENANT_REFRESH_TOKEN_ERROR = '[session] Switch tenant refresh token error';
export const SWITCH_TENANT_REFRESH_TOKEN_SUCCESS = '[session] Switch tenant refresh token success';
export const SAVE_USER_SETTING = '[session] Save user setting';
export const REMOVE_USER_SETTING = '[session] Remove user setting';
export const INITIALIZE_USER_SETTING = '[session] Initialize user setting';
export const LOAD_SETTINGS_SUCCESS = '[session] Load Settings success';
export const LOAD_SETTINGS_ERROR = '[session] Load Settings error';

export class InitializeUserSetting implements Action {
  readonly type = INITIALIZE_USER_SETTING;
  constructor(public component: string, public value: any) {}
}

export class SaveUserSettingAction implements Action {
  readonly type = SAVE_USER_SETTING;
  constructor(public component: string, public value: any) {}
}

export class RemoveUserSetting implements Action {
  readonly type = REMOVE_USER_SETTING;
  constructor(public component: string, public key: string) {}
}

export class AuthenticateAction implements Action {
  readonly type = AUTHENTICATE;
  constructor(public payload: {email: string, password: string}) {}
}

export class AuthenticationErrorAction implements Action {
  readonly type = AUTHENTICATE_ERROR;
  constructor(public error: Error) {}
}

export class AuthenticationSuccessAction implements Action {
  readonly type = AUTHENTICATE_SUCCESS;
  constructor(public payload?: any ) {}
}

export class LoadAuthenticatedUserAction implements Action {
  readonly type = LOAD_AUTHENTICATED_USER;
  constructor(public payload?: any) {}
}

export class LoadAuthenticatedUserSuccessAction implements Action {
  readonly type = LOAD_AUTHENTICATED_USER_SUCCESS;
  constructor(public payload?: User) {}
}

export class LoadAuthenticatedUserErrorAction implements Action {
  readonly type = LOAD_AUTHENTICATED_USER_ERROR;
  constructor(public error: Error) {}
}

export class SignOutAction implements Action {
  readonly type = SIGN_OUT;
  constructor(public idToken: string) {}
}

export class SignOutErrorAction implements Action {
  readonly type = SIGN_OUT_ERROR;
  constructor(public error: Error) {}
}

export class SignOutSuccessAction implements Action {
  readonly type = SIGN_OUT_SUCCESS;
  constructor(public payload?: any) {}
}

export class SignOutSessionTimeoutAction implements Action {
  readonly type = SIGN_OUT_SESSION_TIMEOUT;
  constructor(public payload?: any) {}
}

export class TokenRefreshSuccessAction implements Action {
  readonly type = TOKEN_REFRESH_ACTION_SUCCESS;
  constructor(public payload?: any ) {}
}

export class SwitchTenantAction implements Action {
  readonly type = SWITCH_TENANT;
  constructor(public newTenant: string) {}
}

export class SwitchTenantErrorAction implements Action {
  readonly type = SWITCH_TENANT_ERROR;
  constructor(public error: Error) {}
}

export class SwitchTenantSuccessAction implements Action {
  readonly type = SWITCH_TENANT_SUCCESS;
  constructor(public payload?: User) {}
}

export class SwitchTenantRefreshTokenAction implements Action {
  readonly type = SWITCH_TENANT_REFRESH_TOKEN;
  constructor() {}
}

export class SwitchTenantRefreshTokenErrorAction implements Action {
  readonly type = SWITCH_TENANT_REFRESH_TOKEN_ERROR;
  constructor(public error: Error) {}
}

export class SwitchTenantRefreshTokenSuccessAction implements Action {
  readonly type = SWITCH_TENANT_REFRESH_TOKEN_SUCCESS;
  constructor(public payload?: User) {}
}

export class LoadSettingsSuccessAction implements Action {
  readonly type = LOAD_SETTINGS_SUCCESS;
  constructor(public settings: any) {}
}

export class LoadSettingsErrorAction implements Action {
  readonly type = LOAD_SETTINGS_ERROR;
  constructor(public error: Error) {}
}


/**
 * Actions type.
 * @type {Actions}
 */
export type Actions
=
AuthenticateAction
| AuthenticationErrorAction
| AuthenticationSuccessAction
| LoadAuthenticatedUserAction
| LoadAuthenticatedUserSuccessAction
| LoadAuthenticatedUserErrorAction
| SignOutAction
| SignOutErrorAction
| SignOutSuccessAction
| SignOutSessionTimeoutAction
| TokenRefreshSuccessAction
| SwitchTenantAction
| SwitchTenantErrorAction
| SwitchTenantSuccessAction
| SaveUserSettingAction
| InitializeUserSetting
| LoadSettingsSuccessAction
| LoadSettingsErrorAction;

