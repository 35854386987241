import { NgModule } from '@angular/core';
import { SessionService } from './session.service';
import { StoreModule } from '@ngrx/store';
import { reducer } from './session.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './session.effects';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SessionGuardService } from './session-guard.service';

@NgModule({
  imports: [
    StoreModule.forFeature('session', reducer),
    EffectsModule.forFeature([ SessionEffects ]),
    OAuthModule.forRoot()
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    SessionService,
    SessionGuardService
  ]
})
export class SessionModule {}
