
declare var ENV: string;
declare var GENERAL_TIMEOUT: number;
declare var GENERAL_POLLING_INTERVALL: number;
declare var GENERAL_CLOUDFRONT_DISTRIBUTION_ID: string;
declare var GENERAL_CLOUDFRONT_DISTRIBUTION_ID_FILES: string;
declare var GENERAL_BACKEND_URI: string;
declare var GENERAL_HERE_API_KEY: string;
declare var APM_SERVER_URL: string;
declare var APM_ACTIVE: boolean;
declare var require: any
export const environment = {
  production: true,
  version: require('../../package.json').version,
  name: require('../../package.json').name,
  env: ENV,
  generalTimeout: GENERAL_TIMEOUT,
  generalPollingInterval: GENERAL_POLLING_INTERVALL,
  generalCloudfrontDistributionId: GENERAL_CLOUDFRONT_DISTRIBUTION_ID,
  generalCloudfrontDistributionIdFiles: GENERAL_CLOUDFRONT_DISTRIBUTION_ID_FILES,
  generalBackendUri: GENERAL_BACKEND_URI,
  apmServerUrl: APM_SERVER_URL,
  apmActive: APM_ACTIVE,
  generalHereApiKey: GENERAL_HERE_API_KEY,
};
