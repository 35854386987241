import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { localStorageSync } from 'ngrx-store-localstorage';
import { storeLogger } from 'ngrx-store-logger';

import { environment } from '../environments/environment';

// App state
export interface State {
}

// root reducer
export const reducers: ActionReducerMap<State> = {
};

// logger for dev env
export function logger(reducer: ActionReducer<State>): any {
  // default, no options
  return storeLogger()(reducer);
}

// storage syncer
export function onSyncError(err) {
  console.log(err);
}

export const storageSyncReducer = localStorageSync({
  keys: [{ session: ['authenticated', 'access_token', 'id_token', 'refresh_token', 'user', 'userSettings', 'generalSettings'] } ],
  rehydrate: true
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

// add the logger only for dev
export const metaReducers = environment.production ? [storageMetaReducer] : [logger, storageMetaReducer];

export function getMetaReducers(): MetaReducer<State>[] {
    return metaReducers;
}
