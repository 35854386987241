import {Apollo} from 'apollo-angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, mergeMap, tap, flatMap } from 'rxjs/operators';
import * as fromGraphQl from './graphql';

import { User } from './../shared/entities/user';
import * as session from './session.actions';
import { SessionService } from './session.service';

import * as fromRoot from './../app.reducer';
import { GeneralSettings } from '../tour-planning/entities/generalSettings';

// import @ngrx
// import rxjs
// import services
// import models
// import actions
@Injectable()
export class SessionEffects {
  /**
   * @constructor
   * @param {Actions }actions
   * @param {SessionService} userService
   */
  constructor(
    private actions: Actions,
    private sessionService: SessionService,
    private store: Store<fromRoot.State>,
    private apollo: Apollo,
    private router: Router
  ) {}

  /**
   * Authenticate user.
   * @method authenticate
   */
  @Effect()
  public authenticate$: Observable<Action> = this.actions.pipe(
    ofType(session.AUTHENTICATE),
    switchMap( (action: session.AuthenticateAction) => {
      return this.sessionService.authenticate(action.payload.email, action.payload.password).pipe(
        map(res => new session.AuthenticationSuccessAction(res)),
        catchError(error => of(new session.AuthenticationErrorAction(error))));
  }));

  @Effect()
  public authenticated$: Observable<Action> = this.actions.pipe(
    ofType(session.AUTHENTICATE_SUCCESS),
    switchMap( () => {
      return this.sessionService.loadAuthenticatedUser().pipe(
        map(res => new session.LoadAuthenticatedUserSuccessAction(new User(res))),
        catchError(error => of(new session.AuthenticationErrorAction(error))));
  }));

  // @Effect()
  // public loadSettings$: Observable<Action> = this.actions.pipe(
  //   ofType(session.AUTHENTICATE_SUCCESS),
  //   switchMap( () => {
  //     return this.sessionService.loadSettings().pipe(
  //       map(res => new session.LoadSettingsSuccessAction(res)),
  //       catchError(error => of(new session.AuthenticationErrorAction(error))));
  // }));

  @Effect({dispatch: false})
  public loadSettings$: Observable<Action> = this.actions.pipe(
    ofType(session.AUTHENTICATE_SUCCESS),
    map((action: any) => action.payload),
    tap(
      payload => {
      this.apollo.watchQuery({
        query: fromGraphQl.GET_GENERAL_SETTINGS_QUERY,
        variables: {
          projectID : payload.projectId
        }
      }).valueChanges.subscribe((response: any) => {
        console.log(response);
        this.store.dispatch(new session.LoadSettingsSuccessAction(new GeneralSettings(response.data.getGeneralSettings))
          );
      });
    }
  )
);


  @Effect({ dispatch: false })
  public authenticatedUserLoaded$: Observable<Action> = this.actions.pipe(
    ofType(session.LOAD_AUTHENTICATED_USER_SUCCESS),
    tap( () =>  this.router.navigate(["/"]))
  );

  @Effect()
  public signOut$: Observable<Action> = this.actions.pipe(
    ofType(session.SIGN_OUT),
    switchMap( (action: session.SignOutAction) => {
      return this.sessionService.signOut(action.idToken).pipe(
        map(value => new session.SignOutSuccessAction()),
        catchError(error => of(new session.SignOutErrorAction(error))));
  }));

  @Effect({ dispatch: false })
  public signOutSessionTimeout$: Observable<Action> = this.actions.pipe(
    ofType(session.SIGN_OUT_SESSION_TIMEOUT, session.SIGN_OUT_SUCCESS),
    tap( () =>  this.router.navigate(["/signIn"]))
  );

  @Effect()
  public switchTenant$: Observable<Action> = this.actions.pipe(
    ofType(session.SWITCH_TENANT),
    switchMap( (action: session.SwitchTenantAction) => {
      return this.sessionService.switchTenant(action.newTenant).pipe(
        flatMap( res => {
          return this.sessionService.tryRefresh().pipe(
            map(() => new session.SwitchTenantSuccessAction(new User(res))),
            catchError(error => of(new session.SwitchTenantErrorAction(error))));
        }));
  }));

  @Effect({ dispatch: false })
  public tenantSwitched$: Observable<Action> = this.actions.pipe(
    ofType(session.SWITCH_TENANT_SUCCESS),
    tap( () =>  location.reload())
  );

}
