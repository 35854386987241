export class GeneralSettings {
  schedulePickupInTasksAutomatically: boolean;
  protectLocallyEditedAddresses: boolean;
  importServiceAddressFirst: boolean;
  sitesActive: boolean;
  cloudFrontDistributionId: string;
  showDamagesOnCustomerDocuments: boolean;
  showTermsOnPublicWebView: boolean;
  apiKey: string;
  useNewRentalTaskCreationStrategy: boolean;
  partnerSystem: string;
  protectPositionsWithExectutedTasks: boolean;


  constructor(payload?: any) {
    if (payload) {
      this.schedulePickupInTasksAutomatically = payload.schedulePickupInTasksAutomatically;
      this.protectLocallyEditedAddresses = payload.protectLocallyEditedAddresses;
      this.importServiceAddressFirst = payload.importServiceAddressFirst;
      this.sitesActive = payload.sitesActive;
      this.cloudFrontDistributionId = payload.cloudFrontDistributionId;
      this.showDamagesOnCustomerDocuments = payload.showDamagesOnCustomerDocuments;
      this.showTermsOnPublicWebView = payload.showTermsOnPublicWebView;
      this.useNewRentalTaskCreationStrategy = payload.useNewRentalTaskCreationStrategy;
      this.apiKey = payload.apiKey;
      this.partnerSystem = payload.partnerSystem;
      this.protectPositionsWithExectutedTasks = payload.protectPositionsWithExectutedTasks;
    }
  }
}
