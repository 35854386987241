import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import * as fromSessionActions from './session.actions';
import { SessionService } from './session.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private injector: Injector) {}

  intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add content type to PATCH or POST methods
    if ( (request.method === "PATCH" || request.method === "POST")
      && !(request.url.includes('uploadPDFTemplate') || request.url.includes('uploadMaillingLogo') ) ) {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json"
        }
      });
    }

    // change 'graphql/' with the actual URI
    if(request.url.includes('graphql')){
      request = request.clone({
        url : environment.generalBackendUri + '/graphql'
      })
    }

    // add a X-Request-ID to each request to trace it in the backend
    request = request.clone({
      setHeaders: {
        "X-Request-ID": UUID.UUID()
      }
    });

    return <any>next.handle(this.addAccessToken(request)).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              // console.log("handle 401");
              return this.handle401Error(request, next);
            case 403:
              // console.log("handle 403");
              return this.handle403Error(request, next);
            default:
              return observableThrowError(error);
          }
        } else {
          return observableThrowError(error);
        }
      })
    );
  }

  addAccessToken(request: HttpRequest<any>): HttpRequest<any> {
    const sessionService = this.injector.get(SessionService);
    let accessToken = sessionService.getAccessToken();
    // if its a public link we don´t want to have an access token included in the request
    if ( !(request.url.includes('public') || ( (request.headers.get("x-auth-accessKey") != null) && (request.headers.get("x-auth-tenantId") != null)))) { // dont add an access token
      if (accessToken && !this.isAuthOrRefreshRequest(request)) {
        return request.clone({
          setHeaders: { Authorization: "Bearer " + accessToken }
        });
      }
    }
    return request;
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    const sessionService = this.injector.get(SessionService);
    // console.log("isRefreshingToken: " + this.isRefreshingToken);
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      return sessionService.tryRefresh().pipe(
        switchMap((refreshed: boolean) => {
          // console.log("refressh success: " + refreshed);
          if (refreshed) {
            this.isRefreshingToken = false;
            // console.log("reset isRefresshing to " + this.isRefreshingToken);
            this.tokenSubject.next(refreshed);
            return next.handle(this.addAccessToken(req));
          }
          const store = this.injector.get(Store);
          store.dispatch(new fromSessionActions.SignOutSessionTimeoutAction());
          return observableThrowError("Logout");
        }),
        catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return observableThrowError("Logout");
        }),
        finalize(() => {
          this.isRefreshingToken = false;
          // console.log("reset isRefresshing to " + this.isRefreshingToken);
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter(refreshed => refreshed != null),
        take(1),
        switchMap(refreshed => {
          // console.log("???????????: " + refreshed);
          return next.handle(this.addAccessToken(req));
        })
      );
    }
  }

  handle403Error(req: HttpRequest<any>, next: HttpHandler) {
    const store = this.injector.get(Store);
    store.dispatch(new fromSessionActions.SignOutSessionTimeoutAction());
      return observableThrowError("Session abgelaufen");
  }

  private isAuthOrRefreshRequest(request: HttpRequest<any>): boolean {
    return request.url.includes("refreshToken") || request.url.includes("authenticate");
  }
}
