import { createFeatureSelector, createSelector } from '@ngrx/store';

import { User } from './../shared/entities/user';
import { GeneralSettings } from './../tour-planning/entities/generalSettings';
import * as session from './session.actions';

// import actions
/**
 * The state.
 * @interface State
 */
export interface State {
  authenticated: boolean;
  error: any;
  message: string;
  loaded: boolean;
  loading: boolean;
  user: User | undefined;
  id_token: string | undefined;
  access_token: string | undefined;
  refresh_token: string | undefined;
  userSettings: { [s: string]: any; } | undefined;
  generalSettings: GeneralSettings | undefined;
}

export const initialState: State = {
  authenticated: false,
  error: undefined,
  message: undefined,
  loaded: false,
  loading: false,
  user: undefined,
  id_token: undefined,
  access_token: undefined,
  refresh_token: undefined,
  userSettings: {},
  generalSettings: undefined
};

/**
 * The reducer function.
 * @function reducer
 * @param {State} state Current state
 * @param {Actions} action Incoming action
 */
export function reducer(state = initialState, action: session.Actions): State {
  switch (action.type) {
    case session.AUTHENTICATE:
    case session.LOAD_AUTHENTICATED_USER:
    case session.SIGN_OUT:
    case session.SWITCH_TENANT:
      return Object.assign({}, state, {
        error: undefined,
        message: undefined,
        loading: true,
        loaded: false
      });
    case session.AUTHENTICATE_SUCCESS:
      return Object.assign({}, state, {
        authenticated: true,
        // loaded: true,
        // loading: false,
        error: undefined,
        message: undefined,
        access_token: action.payload.access_token,
        id_token: action.payload.id_token,
        refresh_token: action.payload.refresh_token
      });
    case session.SWITCH_TENANT_SUCCESS:
    case session.LOAD_AUTHENTICATED_USER_SUCCESS:
      return Object.assign({}, state, {
        user: action.payload,
        error: undefined,
        message: undefined,
        loading: false,
        loaded: true
      });
    case session.SIGN_OUT_SUCCESS:
      return Object.assign({}, state, {
        message: "Logout erfolgreich!",
        authenticated: false,
        loaded: true,
        loading: false,
        access_token: undefined,
        id_token: undefined,
        refresh_token: undefined,
        issued_at: undefined,
        user: undefined
      });
    case session.SIGN_OUT_SESSION_TIMEOUT:
    case session.SIGN_OUT_ERROR:
      return Object.assign({}, state, {
        message: "Session abgelaufen - bitte erneut anmelden",
        authenticated: false,
        loaded: true,
        loading: false,
        access_token: undefined,
        id_token: undefined,
        refresh_token: undefined,
        issued_at: undefined,
        user: undefined
      });
    case session.AUTHENTICATE_ERROR:
    case session.LOAD_AUTHENTICATED_USER_ERROR:
    case session.SWITCH_TENANT_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        authenticated: false,
        loaded: true,
        loading: false,
        access_token: undefined,
        id_token: undefined,
        refresh_token: undefined,
        issued_at: undefined,
        user: undefined
      });
    case session.TOKEN_REFRESH_ACTION_SUCCESS:
      return Object.assign({}, state, {
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token
      });
    case session.SAVE_USER_SETTING:
      return Object.assign({}, state, {
        userSettings: saveUserSetting(state.userSettings, action.component, action.value)
      });
    case session.INITIALIZE_USER_SETTING:
      return Object.assign({}, state, {
        userSettings: initializeUserSetting(state.userSettings, action.component, action.value)
      });
    case session.LOAD_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        generalSettings: action.settings
      });
    default:
      return state;
  }
}

export function saveUserSetting(userSettings: { [s: string]: any; }, component: string, value: any): { [s: string]: any; } {
  let newUserSettings = {};
  Object.assign(newUserSettings, userSettings);
  newUserSettings[component] = value;
  return newUserSettings;
}

export function initializeUserSetting(userSettings: { [s: string]: any; }, component: string, value: any): { [s: string]: any; } {
  // check, if user settings are existing, if not create
  if (!userSettings) {
    userSettings = {};
  }
  // check, if user settings for this component are existing, if note create
  if (!userSettings[component]) {
    let newUserSettings = {};
    Object.assign(newUserSettings, userSettings);
    newUserSettings[component] = value;
    return newUserSettings;
  }
  // do nothing and skip initialization
  return userSettings;
}

export const selectFeature = createFeatureSelector<State>("session");
export const selectAccessToken = createSelector(
  selectFeature,
  (state: State) => state.access_token
);
export const selectRefreshToken = createSelector(
  selectFeature,
  (state: State) => state.refresh_token
);
export const isAuthenticated = createSelector(
  selectFeature,
  (state: State) => state.authenticated
);
export const currentUser = createSelector(
  selectFeature,
  (state: State) => state.user
);
export const selectError = createSelector(
  selectFeature,
  (state: State) => state.error
);
export const selectMessage = createSelector(
  selectFeature,
  (state: State) => state.message
);
export const selectLoading = createSelector(
  selectFeature,
  (state: State) => state.loading
);
export const selectUserSettings = createSelector(
  selectFeature,
  (state: State) => state.userSettings
);
export const selectGeneralSettings = createSelector(
  selectFeature,
  (state: State) => state.generalSettings
);

export const getAuthenticated = (state: State) => state.authenticated;
export const getError = (state: State) => state.error;
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getUser = (state: State) => state.user;
export const getIdToken = (state: State) => state.id_token;
