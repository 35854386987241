import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionService } from './session.service';
import { Observable } from 'rxjs';
import { map, catchError } from '../../../node_modules/rxjs/operators';

@Injectable()
export class SessionGuardService implements CanActivate {

  constructor(
    private sessionService: SessionService,
    public router: Router) {}

  canActivate(): Observable<boolean> {
    return this.sessionService.isAuthenticated().pipe(
      map( canActivate => {
        if (!canActivate) {
          this.router.navigate(['/signIn']);
          return false;
        }
        return true;
      })
    );
  }

}
