import {gql} from 'apollo-angular';


import { GeneralSettings } from './../tour-planning/entities/generalSettings';

export const GET_GENERAL_SETTINGS_QUERY = gql`
query generalSettingsQuery
  {
    getGeneralSettings {
      schedulePickupInTasksAutomatically
      importServiceAddressFirst
      sitesActive
      cloudFrontDistributionId
    }
  }
  `;

export interface GeneralSettingsQueryResponse {
  generalSettings: GeneralSettings;
}


