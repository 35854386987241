import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as apm from '@elastic/apm-rum';
import { Store } from '@ngrx/store';
import { Observable, throwError as observableThrowError, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../shared/entities/user';
import * as fromSessionActions from './session.actions';
import * as fromSession from './session.reducer';
/**
 * The user service.
 */
@Injectable()
export class SessionService implements OnDestroy {

  private accessToken: string;
  private accessTokenSub: Subscription;
  private refreshToken: string;
  private refreshTokenSub: Subscription;
  private jwtHelper: JwtHelperService;
  private userSub: Subscription;


  constructor(
    private http: HttpClient,
    private store: Store<fromSession.State>) {
      this.accessTokenSub = this.store.select(fromSession.selectAccessToken).subscribe(accessToken => this.accessToken = accessToken);
      this.refreshTokenSub = this.store.select(fromSession.selectRefreshToken).subscribe(refreshToken => this.refreshToken = refreshToken);
      this.jwtHelper = new JwtHelperService();
      this.userSub = this.store.select(fromSession.currentUser).pipe(
        distinctUntilChanged()
      ).subscribe( (user: User) => {
        if (user) {
          apm.apm.setUserContext({
            id: user.id,
            username: user.login,
            email: user.email
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.accessTokenSub.unsubscribe();
    this.refreshTokenSub.unsubscribe();
  }
   /**
   * Authenticate the user
   *
   * @param {string} email The user's email address
   * @param {string} password The user's password
   * @returns {Observable<any>} The authenticated user observable.
   */
  public authenticate(email: string, password: string): Observable<any> {
    const url = environment.generalBackendUri + '/api/authenticate';
    const body = {
      username: email,
      password: password
    };
    return this.call(this.http.post(url, body));
  }

  /**
   * Returns the authenticated user
   * @returns {User}
   */
  public loadAuthenticatedUser(): Observable<any> {
    const url = environment.generalBackendUri + '/api/account';
    return this.call(this.http.get(url));
  }

  /**
   * End session
   * @returns {Observable<any>}
   */
  public signOut(refreshToken?: string): Observable<any> {
    const url = environment.generalBackendUri  + '/api/logout?token=' + this.refreshToken;
    return this.call(this.http.get(url));
  }

  public isAuthenticated(): Observable<boolean> {
    if (this.accessToken === undefined) {
      return of(false);
    } if (this.jwtHelper.isTokenExpired(this.accessToken)) {
      return this.tryRefresh();
    }
    return of(true);
  }

  public tryRefresh(): Observable<boolean> {
    const url = environment.generalBackendUri + '/api/refreshV2?refreshToken=' + this.refreshToken;
    return this.http.get(url).pipe(
      
      map( (res: any) => {
        if ( res && res.access_token ) {
          this.store.dispatch(new fromSessionActions.TokenRefreshSuccessAction(res));
          return true;
        }
        return false;
      })
    );
  }

  public switchTenant(newTenantId: string): Observable<any> {
    const url = environment.generalBackendUri + '/api/changeTenant?newTenantId=' + newTenantId;
    return this.call(this.http.get(url));
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  call(call: Observable<any>): Observable<any> {
    return call.pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error))
    );
  }

}
