export class User {
  id: string;
  login: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  status: string;
  password: string;
  tenantMembership: string[];
  tenant: string;
  site: string;
  employeeNumber: string;

  constructor(payload?: any ) {
    if (payload) {
      this.email = payload.email;
      this.login = payload.login;
      this.firstName = payload.firstName || payload.givenName;
      this.lastName = payload.lastName || payload.familyName;
      this.roles = payload.roles;
      this.id = payload.id;
      this.status = payload.status;
      this.password = payload.password;
      this.tenantMembership = payload.tenantMembership;
      this.tenant = payload.tenant;
      this.site = payload.site;
      this.employeeNumber = payload.employeeNumber
    }
  }



}
